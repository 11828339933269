import $ from "jquery"

const getInputs = (className: string) =>
  Array.from(document.getElementById("new_action")?.getElementsByClassName(className) || []) as HTMLInputElement[]

document.addEventListener("DOMContentLoaded", () => {
  $("body").on("input", ".txn_txn_at", function () {
    getInputs("txn_txn_at").map(field => (field.value = (this as HTMLInputElement).value))
  })

  $("body").on("input", ".txn_txn_amount", function () {
    const action = getInputs("txn_txn_action").map(field => field.value)[0]

    for (const field of getInputs("txn_txn_amount")) {
      field.value = field.value.replace(",", ".")

      if (field !== this) {
        let value = parseFloat((this as HTMLInputElement).value)

        if (action !== "overpaid" && action !== "reminder_paid") {
          value *= -1
        }

        field.value = value.toString()
      }
    }
  })

  $("body").on("input", ".txn_txn_id", function () {
    getInputs("txn_txn_id").map(field => (field.value = (this as HTMLInputElement).value))
  })
})
