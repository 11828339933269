import validations from "@/common/util/validations"

function cleanField(selector: string) {
  const val = document.querySelector<HTMLInputElement>(selector)?.value

  if (val) {
    return val
      .toString()
      .toUpperCase()
      .replace(/[\W_]+/g, "")
  }

  return null
}

function adminFieldsSuccess() {
  document.querySelector("#customer_org_id")?.classList.remove("field-error")
  document.querySelector("#customer_gln_number")?.classList.remove("field-error")
  document.querySelector("#commit")?.removeAttribute("disabled")
  document.querySelector("form.edit_customer")?.removeAttribute("disabled")
}

function adminOrgidFail() {
  document.querySelector("#customer_org_id")?.classList.add("field-error")
  document.querySelector("#commit")?.setAttribute("disabled", "disabled")
  document.querySelector("form.edit_customer")?.removeAttribute("disabled")
}

function adminGlnFail() {
  document.querySelector("#customer_gln_number")?.classList.add("field-error")
  document.querySelector("#commit")?.setAttribute("disabled", "disabled")
  document.querySelector("form.edit_customer")?.removeAttribute("disabled")
}

function adminValidateFields() {
  if (document.querySelectorAll("#customer_org_id, #customer_gln_number").length === 0) {
    return adminFieldsSuccess()
  }

  const orgid = document.querySelector<HTMLInputElement>("#customer_org_id")?.value
  const gln = cleanField("#customer_gln_number")
  const invoiceCountry = cleanField("#customer_country, #customer_delivery_country")

  const adminGlnFailed = gln && invoiceCountry && !validations.validateGlnNumber(gln, invoiceCountry)
  const adminOrgidFailed = orgid && invoiceCountry && !validations.validateOrgID(orgid, invoiceCountry)

  if (!adminGlnFailed && !adminOrgidFailed) {
    return adminFieldsSuccess()
  }

  if (adminGlnFailed) {
    adminGlnFail()
  } else {
    document.querySelector("#customer_gln_number")?.classList.remove("field-error")
  }

  if (adminOrgidFailed) {
    adminOrgidFail()
  } else {
    document.querySelector("#customer_org_id")?.classList.remove("field-error")
  }
}

document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelectorAll("form.edit_customer").length === 0) {
    return
  }

  adminValidateFields()

  document.body.addEventListener("change", e => {
    const targetId = (e.target as HTMLInputElement).id

    if (
      [
        "customer_country",
        "customer_delivery_country",
        "customer_vat_id",
        "customer_org_id",
        "customer_gln_number",
      ].includes(targetId)
    ) {
      adminValidateFields()
    }
  })

  document.body.addEventListener("input", e => {
    const targetId = (e.target as HTMLInputElement).id

    if (["customer_vat_id", "customer_org_id", "customer_gln_number"].includes(targetId)) {
      adminValidateFields()
    }
  })

  document.body.addEventListener("keyup", e => {
    const targetId = (e.target as HTMLInputElement).id

    if (["customer_vat_id", "customer_org_id", "customer_gln_number"].includes(targetId)) {
      adminValidateFields()
    }
  })

  function triggerEvent(element: HTMLInputElement, eventType: string) {
    const event = new Event(eventType, { bubbles: true, cancelable: true })
    element.dispatchEvent(event)
  }

  const vatId = document.querySelector<HTMLInputElement>("#customer_vat_id")
  const orgId = document.querySelector<HTMLInputElement>("#customer_org_id")
  const glnNumber = document.querySelector<HTMLInputElement>("#customer_gln_number")

  if (vatId) {
    triggerEvent(vatId, "keyup")
    triggerEvent(vatId, "change")
    triggerEvent(vatId, "input")
  } else if (orgId) {
    triggerEvent(orgId, "keyup")
    triggerEvent(orgId, "change")
    triggerEvent(orgId, "input")
  } else if (glnNumber) {
    triggerEvent(glnNumber, "keyup")
    triggerEvent(glnNumber, "change")
    triggerEvent(glnNumber, "input")
  }
})
