import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  timeout?: number

  connect() {
    this.timeout = window.setTimeout(() => this.close(), 5000)
  }

  close() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.timeout = window.setTimeout(() => this.element.remove(), 200)
    this.element.classList.add("opacity-0")
  }
}
