import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["btn"]

  declare btnTarget?: HTMLAnchorElement
  declare hasBtnTarget: boolean

  private timeout: number

  connect() {
    this.timeout = window.setTimeout(() => {
      this.startClose()
    }, 10000)
  }

  startClose() {
    this.btnTarget?.click()
  }

  close() {
    if (this.timeout) clearTimeout(this.timeout)
    this.element.classList.add("hidden")
    document.getElementById("find_id")?.focus()
  }
}
