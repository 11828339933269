import { Controller } from "@hotwired/stimulus"

const sortOptions = ["initial", "descending", "ascending"]

export default class extends Controller {
  static targets = ["sortablesWrapper", "sortAscending", "sortDescending"]

  declare sortablesWrapperTarget: HTMLDivElement
  declare hasSortablesWrapperTarget: boolean

  declare sortAscendingTarget: HTMLDivElement
  declare hasSortAscendingTarget: boolean

  declare sortDescendingTarget: HTMLDivElement
  declare hasSortDescendingTarget: boolean

  sortIndex = 0

  sort(e: CustomEvent) {
    e?.preventDefault()

    const initial = e.detail.initial
    const param1 = e.detail.param1
    const param2 = e.detail.param2

    const children = Array.from(this.sortablesWrapperTarget.children)

    this.sortIndex = this.sortIndex == 2 ? 0 : this.sortIndex + 1
    const next = sortOptions[this.sortIndex]

    let sorted: Element[]

    if (next == "ascending") {
      sorted = children.sort((elem1: HTMLElement, elem2: HTMLElement) =>
        this.sortAscending(elem1.dataset[param1] || "", elem2.dataset[param1] || "")
      )

      this.sortAscendingTarget.classList.remove("hidden")
      this.sortDescendingTarget.classList.add("hidden")
    } else if (next == "descending") {
      sorted = children.sort((elem1: HTMLElement, elem2: HTMLElement) =>
        this.sortDescending(elem1.dataset[param1] || "", elem2.dataset[param1] || "")
      )

      this.sortDescendingTarget.classList.remove("text-gray-400")
    } else {
      sorted = children.sort((elem1: HTMLElement, elem2: HTMLElement) =>
        this.sortDescending(initial.indexOf(elem1.dataset[param2] || ""), initial.indexOf(elem2.dataset[param2] || ""))
      )

      this.sortAscendingTarget.classList.add("hidden")
      this.sortDescendingTarget.classList.remove("hidden")
      this.sortDescendingTarget.classList.add("text-gray-400")
    }

    this.sortablesWrapperTarget.innerHTML = ""
    sorted.forEach(child => this.sortablesWrapperTarget.appendChild(child))
  }

  sortDescending(val1: string | number, val2: string | number) {
    if (val1 < val2) {
      return -1
    } else if (val1 > val2) {
      return 1
    } else {
      return 0
    }
  }

  sortAscending(val1: string | number, val2: string | number) {
    if (val1 > val2) {
      return -1
    } else if (val1 < val2) {
      return 1
    } else {
      return 0
    }
  }
}
