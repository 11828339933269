import { Controller } from "@hotwired/stimulus"
import * as qz from "qz-tray"
import type { PrintData } from "qz-tray"
import apolloClient from "@/api/client"
import env from "@/common/env"
import { graphql } from "@/api"

const qzRequestSignDoc = graphql(`
  mutation QzRequestSign($request: String!) {
    qzRequestSign(request: $request) {
      signature
      error
    }
  }
`)

export default class extends Controller {
  static values = { zplData: String, autoload: Boolean }

  declare zplDataValue: string
  declare autoloadValue: boolean

  connect() {
    if (env.TEST) {
      return
    }

    qz.security.setSignatureAlgorithm("SHA512")

    qz.security.setCertificatePromise(function (resolve, reject) {
      fetch("/qztray.pem", { cache: "no-store", headers: { "Content-Type": "text/plain" } })
        .then(data => {
          if (data.ok) {
            data
              .text()
              .then(cert => resolve(cert))
              .catch(err => {
                console.error("unable to parse certificate", err)
                reject(err)
              })

            return
          }

          reject("unable to fetch certificate")
        })
        .catch(err => {
          console.error("unable to fetch certificate", err)
          reject(err)
        })
    })

    qz.security.setSignaturePromise(function (request) {
      return function (resolve, reject) {
        apolloClient
          .mutate({
            mutation: qzRequestSignDoc,
            variables: { request },
          })
          .then(result => {
            if (result.data?.qzRequestSign?.signature) {
              resolve(result.data?.qzRequestSign?.signature)
              return
            }

            if (result.data?.qzRequestSign?.error) {
              const err = result.data?.qzRequestSign?.error
              console.error(err)
              reject(err)
            }
          })
          .catch(err => {
            console.error("unable to sign request", err)
            reject(err)
          })
      }
    })

    qz.websocket
      .connect()
      .then(() => {
        if (this.autoloadValue) {
          this.print()
        }
      })
      .catch(err => console.error("unable to connect websocket", err))
  }

  print() {
    if (!qz.websocket.isActive()) {
      console.log("qz tray not active")
      return
    }

    qz.printers
      .find()
      .then(printers => {
        const allPrinters = typeof printers == "string" ? [printers] : printers

        let printer: string | undefined
        const primary = "Brother TD-4420TN"
        const secondary = "ZDesigner GX420d"

        if (allPrinters.includes(primary)) {
          printer = primary
        } else if (allPrinters.includes(secondary)) {
          printer = secondary
        }

        if (!printer) {
          const alertMsg = `No label printer found. Looking for a printer named ${primary} or ${secondary}`

          if (env.RAILS_ENV == "development") {
            console.warn(alertMsg)
          } else {
            alert(alertMsg)
          }

          return
        }

        let config = qz.configs.create(printer, { encoding: "UTF-8" })

        if (env.RAILS_ENV == "development") {
          config = qz.configs.create({ host: "127.0.0.1", port: "9099" }, { encoding: "ISO-8859-1" })
        }

        const data: PrintData[] = JSON.parse(this.zplDataValue)

        return qz.print(config, data)
      })
      .catch(err => {
        console.error(err)
      })
  }
}
