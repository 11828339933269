import { Controller } from "@hotwired/stimulus"
import EpicVite from "@skyltmax/epic_vite"
import { v4 } from "uuid"

const isBefore = (el1: HTMLElement, el2: HTMLElement) => {
  if (el2.parentNode === el1.parentNode) {
    for (let cur = el1.previousSibling; cur && cur.nodeType !== 9; cur = cur.previousSibling) {
      if (cur === el2) {
        return true
      }
    }
  }

  return false
}

let dragTarget: HTMLElement | null

export default class extends Controller {
  static targets = ["kindChange"]

  declare kindChangeTarget: HTMLInputElement
  declare hasKindChangeTarget: boolean

  get form() {
    return this.element as HTMLFormElement
  }

  connect() {
    this.updateDraggables()
    this.checkBlockLimits()
  }

  changeKind(e: Event) {
    if (e.target instanceof HTMLSelectElement && this.hasKindChangeTarget) {
      this.kindChangeTarget.value = e.target.value
      this.form.submit()
    }
  }

  addBlock(e: Event) {
    e.preventDefault()

    if (!(e.currentTarget instanceof HTMLElement)) {
      return
    }

    const blockGroup = e.currentTarget.closest("[data-cms-blocks]")
    const template = blockGroup?.querySelector("[data-cms-template]")

    if (template) {
      const clone = template.cloneNode(true) as HTMLElement

      for (const field of Array.from(clone.querySelectorAll<HTMLInputElement>("input[disabled], textarea[disabled]"))) {
        field.disabled = false
      }

      // hook up stimulus controllers
      for (const el of Array.from(clone.querySelectorAll<HTMLElement>("[data-controller^='cms-template-']"))) {
        el.dataset["controller"] = el.dataset["controller"]?.replace("cms-template-", "")
      }

      clone.classList.remove("hidden")
      delete clone.dataset["cmsTemplate"]
      clone.dataset["cmsBlock"] = ""
      template.parentNode?.insertBefore(clone, template)

      this.updateDraggables()

      // mount react components
      for (const el of Array.from(clone.querySelectorAll<HTMLElement>(".cms-block-react"))) {
        const componentName = el.dataset["componentName"]
        const props = JSON.parse(el.innerText)
        const root = el.parentElement?.querySelector<HTMLElement>(".cms-block-react-root")

        if (componentName && root) {
          const domId = v4()
          root.id = domId
          EpicVite.render(componentName, root, {
            props,
            component: componentName,
            locale: "en-IE",
            url: location.pathname,
          })
        }
      }

      this.checkBlockLimits()
    }
  }

  removeBlock(e: Event) {
    if (e.currentTarget instanceof HTMLElement) {
      e.currentTarget.closest("[data-cms-block]")?.remove()

      this.checkBlockLimits()
    }
  }

  checkBlockLimits() {
    for (const group of Array.from(this.element.querySelectorAll<HTMLElement>("[data-cms-blocks]"))) {
      const limit = parseInt(group.dataset["cmsMaxBlocks"] || "99")
      const addBtn = group.querySelector<HTMLElement>("[data-cms-add-btn]")

      if (limit && addBtn) {
        const count = group.querySelectorAll("[data-cms-block]").length

        if (count >= limit) {
          addBtn.classList.add("hidden")
        } else {
          addBtn.classList.remove("hidden")
        }
      }
    }
  }

  updateDraggables() {
    for (const el of Array.from(this.element.querySelectorAll("[draggable=true]"))) {
      el.removeEventListener("dragstart", this.dragStart)
      el.removeEventListener("dragover", this.dragOver)
      el.removeEventListener("drop", this.dragDrop, false)

      el.addEventListener("dragstart", this.dragStart)
      el.addEventListener("dragover", this.dragOver)
      el.addEventListener("drop", this.dragDrop, false)
    }
  }

  dragStart = (e: DragEvent) => {
    if (e.currentTarget instanceof HTMLElement) {
      if (e.dataTransfer) {
        e.dataTransfer.effectAllowed = "move"
        e.dataTransfer.setData("nothing", "nil")
      }

      dragTarget = e.currentTarget
    }
  }

  dragOver = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.dataTransfer) {
      e.dataTransfer.dropEffect = "move"
    }

    if (e.currentTarget instanceof HTMLElement) {
      if (e.currentTarget === dragTarget) {
        return
      }

      if (!dragTarget) {
        return
      }

      if (isBefore(dragTarget, e.currentTarget)) {
        e.currentTarget.parentNode?.insertBefore(dragTarget, e.currentTarget)
      } else {
        e.currentTarget.parentNode?.insertBefore(dragTarget, e.currentTarget.nextSibling)
      }
    }
  }

  dragDrop = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    dragTarget = null
  }
}
