import { Controller } from "@hotwired/stimulus"
import fetch from "@/common/fetch"

interface PopupParams {
  header: string
  comment?: string
  input?: string
  button1?: {
    text: string
    href: string
    action: string
    focus?: boolean
    close?: boolean
  }
  button2?: {
    text: string
    href: string
    action: string
    focus?: boolean
  }
  error?: {
    invoice_email?: boolean
  }
}

export default class extends Controller {
  static values = { data: {} }

  declare dataValue: PopupParams
  declare hasDataValue: boolean

  static targets = ["wrapper", "header", "comment", "inputField", "button1", "button2"]

  declare wrapperTarget: HTMLDivElement
  declare hasWrapperTarget: boolean

  declare headerTarget: HTMLDivElement
  declare hasHeaderTarget: boolean

  declare commentTarget: HTMLDivElement
  declare hasCommentTarget: boolean

  declare inputFieldTarget: HTMLInputElement
  declare hasInputFieldTarget: boolean

  declare button1Target: HTMLInputElement
  declare hasButton1Target: boolean

  declare button2Target: HTMLInputElement
  declare hasButton2Target: boolean

  open(e: CustomEvent) {
    this.dataValue = JSON.parse(e.detail.options)
    this.toggleWrapper()
    this.setParams()
  }

  setParams() {
    if (this.hasHeaderTarget) {
      this.headerTarget.innerHTML = this.dataValue.header
    }

    if (this.hasCommentTarget) {
      if (this.dataValue.comment) {
        this.commentTarget.innerHTML = this.dataValue.comment
      } else {
        this.commentTarget.classList.add("hidden")
      }
    }

    if (this.hasInputFieldTarget) {
      if (this.dataValue.input) {
        this.inputFieldTarget.value = this.dataValue.input
      } else {
        this.inputFieldTarget.classList.add("hidden")
      }
    }

    if (this.dataValue.button1 && this.hasButton1Target) {
      this.button1Target.innerHTML = this.dataValue.button1.text

      if (this.dataValue.button1.focus) {
        this.button1Target.focus()
      }

      if (this.dataValue.button1.close && this.hasWrapperTarget) {
        this.wrapperTarget.style.background = "rgba(0, 0, 0, 0.5)"
      }
    }

    if (this.dataValue.button2 && this.hasButton2Target) {
      this.button2Target.innerHTML = this.dataValue.button2.text

      if (this.dataValue.button2.focus) {
        this.button2Target.focus()
      }
    } else {
      this.button2Target.hidden = true
    }
  }

  click1(e?: Event) {
    e?.preventDefault()

    if (this.dataValue.button1) {
      this.submit(this.dataValue.button1.href, this.dataValue.button1.action)
    }
  }

  click2(e?: Event) {
    e?.preventDefault()

    if (this.dataValue.button2) {
      this.submit(this.dataValue.button2.href, this.dataValue.button2.action)
    }
  }

  submit(href: string, action: string) {
    if (action === "PUT") {
      fetch(href, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ textfield: this.inputFieldTarget.value }),
      }).then(response => {
        if (response.ok) {
          location.reload()
        }
      })

      // make sure the popup isn't hidden before request completes for tests
      return
    } else if (action == "GET") {
      fetch(href, {
        method: "GET",
      }).then(response => {
        if (response.ok) {
          if (this.dataValue.error?.invoice_email) {
            const invoiceEmail = <HTMLDivElement>document.getElementById("offer_invoice_email")
            const invoiceEmailInput = <HTMLInputElement>document.getElementById("order_invoice_email")

            invoiceEmailInput.style.borderColor = "red"
            invoiceEmail.style.color = "red"

            this.toggleWrapper()
          } else {
            location.reload()
          }
        }
      })

      // make sure the popup isn't hidden before request completes for tests
      return
    }

    this.toggleWrapper()
  }

  toggleWrapper() {
    if (this.hasWrapperTarget) {
      if (this.wrapperTarget.style.display == "block") {
        this.wrapperTarget.style.display = "none"

        if (this.dataValue.button1?.close) {
          const swissUnifaunInput = document.querySelector<HTMLInputElement>("#swiss_unifaun_id")

          if (swissUnifaunInput) {
            swissUnifaunInput.focus()
          }
        }
      } else {
        this.wrapperTarget.style.display = "block"
      }
    }
  }
}
