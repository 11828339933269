import { Controller } from "@hotwired/stimulus"
import fetch from "@/common/fetch"
import MessageBus from "@/common/message_bus"

interface PaymentsRegisterStatus {
  done: number
  total: number
  result?: {
    saved: string
    alerts: string[]
  }
}

const createFlash = (msg: string) => `
<div style="margin: 25px;">
  <div class="flash-message flash-alert">
    <p>${msg}</p>
  </div>
</div>`

export default class extends Controller {
  static targets = ["button", "status", "progress", "returnLink"]

  declare buttonTarget: HTMLInputElement
  declare hasButtonTarget: boolean

  declare statusTarget: HTMLDivElement
  declare hasStatusTarget: boolean

  declare progressTarget: HTMLDivElement
  declare hasProgressTarget: boolean

  declare returnLinkTarget: HTMLAnchorElement
  declare hasReturnLinkTarget: boolean

  private get form() {
    return this.element as HTMLFormElement
  }

  connect() {
    MessageBus.start()
  }

  async submit(e?: Event) {
    e?.preventDefault()

    if (this.hasButtonTarget) {
      this.buttonTarget.disabled = true
      this.buttonTarget.value = "Processing..."
    }

    this.setProgress("")

    if (this.hasReturnLinkTarget) this.returnLinkTarget.classList.add("hidden")

    const data = new FormData(this.form)
    const payments: { [key: string]: { [key: string]: string } } = Array.from(data.entries())
      .filter(([key]) => key.includes("payment"))
      .reduce(
        (acc, [key, value]) => {
          const group: string = key.substring(key.indexOf("[") + 1, key.indexOf("]"))
          const subKey: string = key.substring(key.lastIndexOf("[") + 1, key.lastIndexOf("]"))

          acc[group] = acc[group] || {}
          const subObj: any = acc[group][subKey] || {}
          subObj[key] = value
          acc[group][subKey] = Object.values(subObj).join("")

          if (subKey == "ignore") delete acc[group]

          return acc
        },
        {} as { [key: string]: { [key: string]: string } }
      )

    const paymentsString: string = JSON.stringify({ payments })

    try {
      const resp = await fetch(this.form.action, {
        method: "POST",
        body: paymentsString,
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (resp.ok) {
        const json = await resp.json()

        if (this.hasStatusTarget) this.statusTarget.classList.remove("hidden")

        MessageBus.subscribe(
          `/payments_register/${json.session}`,
          (data: PaymentsRegisterStatus) => {
            if (data.result) {
              if (this.hasStatusTarget) {
                this.statusTarget.innerHTML = createFlash(data.result.saved)

                for (const alert of data.result.alerts) {
                  this.statusTarget.appendChild(document.createRange().createContextualFragment(createFlash(alert)))
                }
              }

              if (this.hasReturnLinkTarget) {
                this.returnLinkTarget.classList.remove("hidden")
                this.returnLinkTarget.href = location.href
              }

              if (this.hasButtonTarget) {
                this.buttonTarget.value = "Done"
                this.buttonTarget.scrollIntoView()
              }
            } else {
              this.setProgress(`Processing payments ${data.done}/${data.total}...`)
            }
          },
          0
        )
      } else if (resp.status < 500) {
        const json = await resp.json()

        if (json.csrf_error && json.csrf_error === true) {
          throw "Your session has expired. Please reload the page and try again."
        }

        throw json.error || json
      } else {
        throw resp.statusText
      }
    } catch (e) {
      alert(e)

      if (this.hasStatusTarget) this.statusTarget.classList.add("hidden")

      if (this.hasButtonTarget) {
        this.buttonTarget.disabled = false
        this.buttonTarget.value = "Try again"
      }
    }
  }

  private setProgress(progress: string) {
    if (this.hasProgressTarget) this.progressTarget.innerText = progress
  }
}
