import $ from "jquery"
import Mousetrap from "mousetrap"
import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"
import DateRangePicker from "daterangepicker"
import "daterangepicker/daterangepicker.css"
import ClientData from "@/common/client_data"
import env from "@/common/env"
import { initEditor } from "./tinymce"
import { Turbo } from "@hotwired/turbo-rails"

Turbo.session.drive = false

import "@/admin/forms"
import "@/admin/adminorder"
import "@/admin/transactions"
import "@/admin/redo"
import "@/admin/customer"

import "@/common/components.css"

const application = Application.start()

try {
  const controllers = import.meta.glob(
    ["./controllers/**/*.ts", "../common/controllers/**/*.ts", "../../components/**/*_controller.ts"],
    { eager: true }
  )

  registerControllers(application, controllers)
} catch (e) {
  console.error(e)
}

$.fn.daterangepicker = function (options, callback) {
  const implementOptions = $.extend(true, {}, $.fn.daterangepicker.defaultOptions, options)
  this.each(function () {
    const el = $(this)

    if (el.data("daterangepicker")) {
      el.data("daterangepicker")?.remove()
    }

    el.data("daterangepicker", new DateRangePicker(this, implementOptions, callback))
  })
  return this
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Window {
    tinymce_css?: string[]
    customs_print?: Window
    invoice_print?: Window
  }
}

document.addEventListener("DOMContentLoaded", () => {
  for (const el of Array.from(document.querySelectorAll<HTMLElement>("a.singleclick, button.singleclick"))) {
    el.addEventListener("click", e => {
      if (el.dataset.triggered) {
        e.preventDefault()
        e.stopPropagation()
        return
      }

      el.dataset.triggered = "true"
      el.classList.add("disabled")

      setTimeout(() => {
        delete el.dataset.triggered
        el.classList.remove("disabled")
      }, 2000)
    })
  }

  initEditor()

  const focusSearch = () => {
    document.getElementById("find_id")?.focus()
  }

  $("#comparison_enable").change(function () {
    $(this).length > 0 && $(this).prop("checked")
      ? $(".reports_compare_side, .reports.compare").show()
      : $(".reports_compare_side, .reports.compare").hide()
  })

  $(".addnewrow").click(e => {
    e.preventDefault()

    $("table").append($(".newrow")[0].outerHTML)
  })

  Mousetrap.bind("+", () => {
    if ($(".cancel_send").length > 0) {
      return alert($(".cancel_send").text())
    }

    let link

    if ($("#set_alu_prod_finished:visible").length > 0) {
      link = $("#set_alu_prod_finished")
    }

    if ($("#send_norway_packed:visible").length > 0) {
      link = $("#send_norway_packed")
    }

    if ($("#send_switzerland_packed:visible").length > 0) {
      link = $("#send_switzerland_packed")
    }

    if (link && $("#customer_pickup_params").length > 0) {
      openCustomerPickupPopup()
      return false
    }

    if (link) {
      link.get(0)?.click()
    }
  })

  $("#set_alu_prod_finished:visible").on("click", (e): any => {
    if (document.querySelector(".cancel_send")) {
      e.preventDefault()
      alert(document.querySelector(".cancel_send")?.textContent)
      return false
    }
    if (document.querySelector("#customer_pickup_params")) {
      openCustomerPickupPopup()
      return false
    }
  })

  if (window.location.pathname.endsWith("/packing")) {
    if (document.querySelector("#order_sent")?.textContent?.trim() === "false") {
      const orderID = document.querySelector("#order_id")?.textContent?.trim()
      const message = `Order ${orderID} is not sent. Continue anyway?`

      const packingCheck = () => {
        if (document.querySelector(".col-cart-sign.redo")) {
          return true
        }

        return confirm(message)
      }

      document.querySelector("#order_form")?.addEventListener("submit", e => {
        if (document.querySelector(".col-cart-sign.redo") || confirm(message) === true) {
          return true
        } else {
          e.preventDefault()
          return false
        }
      })
      $("div.menu a").on("click", packingCheck)
    }
  }

  if (window.location.pathname.endsWith("/sorting")) {
    if (document.querySelector("#suborder_not_done")?.textContent?.trim() === "true") {
      const orderID = document.querySelector("#order_id")?.textContent?.trim()
      const message = `Suborder ${orderID} is not marked as done. Continue anyway?`

      const packingCheck = () => {
        if (document.querySelector(".col-cart-sign.redo")) {
          return true
        }

        return confirm(message)
      }

      document.querySelector("#order_form")?.addEventListener("submit", e => {
        if (document.querySelector(".col-cart-sign.redo") || confirm(message) === true) {
          return true
        } else {
          e.preventDefault()
          return false
        }
      })
      $("div.menu a").on("click", packingCheck)
    }
  }

  $(".prodlinks a").click(function (e) {
    if ($("#process_orders_action").length === 0 || $(this).hasClass("notprodlink")) {
      return
    }

    e.preventDefault()

    if (
      ($(this).hasClass("set_packed_norway") &&
        $(this).data("norwayConfirm") &&
        !confirm("There already are orders in “Norway to send”. Would you like to add these anyway?")) ||
      ($(this).hasClass("set_packed_switzerland") &&
        $(this).data("switzerlandConfirm") &&
        !confirm("There already are orders in “Switzerland to send”. Would you like to add these anyway?"))
    ) {
      return
    }

    // Prevent accidental double clicks
    const link = $(this)
    if (link.data("disabled") === true) {
      return
    }

    if (!$(this).hasClass("nodisable")) {
      link.data("disabled", true)
      link.addClass("disabledprodlink")
    }

    // Copy action to main form and submit
    const form = $("#process_orders_action")
    form.attr("action", link.attr("href") || "")

    if (link.hasClass("invoicegen")) {
      const count = $("#count").val()
      form.attr("action", form.attr("action") + "&count=" + count)
    }

    form.submit()
  })

  $("#process_orders_action").on("submit", function () {
    $(":submit", this).prop("disabled", true)
  })

  const dhlValues = ["DAESU", "DAESI", "DAECX", "DADOM", "DAWPX", "ASPO", "ASWH2", "AEX", "ASWS2"]

  const weighRequired = () => {
    const showmypack = document.querySelector("#unifaun_form .weight")?.getAttribute("data-showmypack")
    const freightValue = document.querySelector<HTMLSelectElement>("#order_unifaun_freight")?.value
    const varubrevValue = ["PUA"]

    return (
      (showmypack && (freightValue === "P19" || freightValue === "BRING")) ||
      dhlValues.includes(freightValue || "") ||
      varubrevValue.includes(freightValue || "")
    )
  }

  $("#unifaun_form").on("submit", function (e) {
    $(":submit", this).prop("disabled", true)

    const freightOptions = document.querySelector<HTMLSelectElement>("#order_unifaun_freight")
    if (
      document.querySelector<HTMLSpanElement>(".delivery_service_name")?.textContent === "" &&
      freightOptions?.classList.contains("hidden")
    ) {
      freightOptions.value = ""
    }

    if (weighRequired() && document.querySelector<HTMLInputElement>("#order_unifaun_weight")?.value === "") {
      return
    }

    const freightValue = freightOptions?.value || ""
    if (["DAESI", "DAWPX"].includes(freightValue) && !ClientData.orderDigitalCustomsDeclaration) {
      const digitalCustomsPopupWrapper = document.querySelector<HTMLDivElement>(".digital_customs_popup_wrapper")

      if (digitalCustomsPopupWrapper) {
        digitalCustomsPopupWrapper.style.display = "block"
      }
    }

    if (document.querySelector<HTMLInputElement>("#no_submit")?.checked) {
      e.preventDefault()
      return
    }
  })

  document.querySelector(".edit_delivery_service")?.addEventListener("click", () => {
    document.querySelector(".show_delivery_service")?.classList.add("hidden")
    document.querySelector(".update_delivery_service")?.classList.remove("hidden")
    document.querySelector("#order_unifaun_freight")?.classList.remove("hidden")
  })

  document.querySelector(".edit_delivery_time")?.addEventListener("click", () => {
    document.querySelector(".show_delivery_time")?.classList.add("hidden")
    document.querySelector(".update_delivery_time")?.classList.remove("hidden")
    document.querySelector(".toggle_delivery_time")?.classList.add("hidden")
  })

  const editUnifaunWeightButton = document.querySelector<HTMLButtonElement>(".edit_unifaun_weight")

  editUnifaunWeightButton?.addEventListener("click", () => {
    const unifaunWeightText = document.querySelector<HTMLElement>(".show_unifaun_weight")
    const unifaunWeightInput = document.querySelector<HTMLElement>("#unifaun_form .weight")

    if (unifaunWeightText && unifaunWeightInput) {
      unifaunWeightText.style.display = "none"

      // change together with unifaunCheck
      unifaunWeightInput.style.display = "inline"
    }
  })

  if (document.querySelector(".digital_customs_popup")) {
    const digital_customs_popup_wrapper = document.querySelector<HTMLDivElement>(".digital_customs_popup_wrapper")
    document.querySelector(".btn.no")?.addEventListener("click", () => {
      if (digital_customs_popup_wrapper) {
        digital_customs_popup_wrapper.style.display = "none"
      }

      focusSearch()
    })

    Mousetrap.bind("esc", () => {
      if (digital_customs_popup_wrapper) {
        digital_customs_popup_wrapper.style.display = "none"
      }
      focusSearch()
    })
  }

  const unifaunCheck = () => {
    const freightOptions = document.querySelector<HTMLSelectElement>("#order_unifaun_freight")
    const freightValue = freightOptions?.value || ""
    const orderUnifaunWeight = document.querySelector<HTMLInputElement>("#order_unifaun_weight")
    let weightValue = orderUnifaunWeight?.value || ""
    const weightDisplay = document.querySelector<HTMLSpanElement>(".show_unifaun_weight")

    if (weighRequired()) {
      const weightInput = document.querySelector<HTMLSpanElement>("#unifaun_form .weight")
      // change together with editUnifaunWeightButton?.addEventListener
      if (weightInput?.style.display == "none") {
        if (weightDisplay) {
          // display the weight span, when the weight input element is not displayed
          weightDisplay.style.display = "inline"
        } else {
          // display the weight input, when the weight span is not present - custom_additional_product orders
          weightInput.style.display = "inline"
        }
      }
      if (
        flashAlert &&
        weightInput &&
        flashAlert.textContent?.includes("Please fill in weight. The order is not reserved.")
      ) {
        weightInput.style.display = "inline"
        if (weightDisplay) weightDisplay.style.display = "none"
      }
    } else {
      weightValue = ""
      if (weightDisplay) weightDisplay.style.display = "none"
    }

    // Denmark letters
    const reserveLetter = document.querySelector<HTMLLinkElement>(".reserve_letter")
    const setFreight = document.querySelector<HTMLButtonElement>("#set-freight")
    if (freightValue == "DKRL" && reserveLetter && setFreight) {
      reserveLetter.style.display = "inline-block"
      setFreight.style.display = "none"
    } else if (freightValue == "PUA" && reserveLetter && setFreight) {
      reserveLetter.style.display = "none"
      setFreight.style.display = "inline-block"
    }

    const sendNorwayPacked = document.querySelector<HTMLLinkElement>("#send_norway_packed")
    const sendSwitzerlandPacked = document.querySelector<HTMLLinkElement>("#send_switzerland_packed")
    const setAluProdFinished = document.querySelector<HTMLLinkElement>("#set_alu_prod_finished")
    if (sendNorwayPacked || sendSwitzerlandPacked) {
      if (["DAESI", "DAWPX"].includes(freightValue) && setAluProdFinished) {
        if (sendNorwayPacked) {
          sendNorwayPacked.style.display = "none"
        } else if (sendSwitzerlandPacked) {
          sendSwitzerlandPacked.style.display = "none"
        }

        setAluProdFinished.style.display = "inline-block"
      } else if (setAluProdFinished) {
        if (sendNorwayPacked) {
          sendNorwayPacked.style.display = "inline-block"
        } else if (sendSwitzerlandPacked) {
          sendSwitzerlandPacked.style.display = "inline-block"
        }

        setAluProdFinished.style.display = "none"
      }
    }

    if (dhlValues.includes(freightValue) && parseInt(weightValue) < 1 && orderUnifaunWeight) {
      orderUnifaunWeight.value = "1"
    }
  }

  document.querySelector("#unifaun_form input[type=submit]")?.addEventListener("click", e => {
    document.getElementById("order_unifaun_weight")?.classList.remove("red_border")
    e.preventDefault()
    focusSearch()

    if (document.querySelector("#remove_traceable_confirm")) {
      document.querySelector("#set_alu_prod_finished")?.removeAttribute("data-confirm")
      document.querySelector("#send_norway_packed")?.removeAttribute("data-confirm")
    }

    $("#unifaun_form").submit()
  })

  const flashAlert = document.getElementById("flash_alert")
  if (flashAlert && flashAlert.textContent?.includes("Please fill in weight. The order is not reserved.")) {
    document.querySelector("#order_unifaun_weight")?.classList.add("red_border")
  }

  $("#unifaun_form").change(unifaunCheck)
  unifaunCheck()

  document.querySelector("#unifaun_form")?.classList.add("loaded")

  $(".edit_customer #customer_different_delivery_address").change(e => {
    if ($(e.target).prop("checked")) {
      const fields = ["first_name", "last_name", "company", "street", "street2", "zipcode", "city", "state", "country"]

      for (const name of fields) {
        const val = $("#customer_" + name).val()
        const deliveryVal = $("#customer_delivery_" + name).val()

        if (val && !deliveryVal) {
          $("#customer_delivery_" + name).val(val)
        }
      }
    }
  })

  $(".txnlogs .attr_expand").on("click", e => {
    const target = $(e.target)

    const openLog = target.text() === "+"
    openLog ? target.text("-") : target.text("+")
    target.parent().parent().find(".attribute_changes").toggle()

    if (openLog) {
      const txnId = $(target).data("txn_id")

      fetch("/admin/transactions/txn_logs?id=" + txnId)
        .then(response => response.json())
        .then(response => {
          const createLog = response.create_log
          const updateLogs = response.update_logs

          let column1 = ""
          let column2 = ""

          if (Object.keys(createLog).length > 0) {
            column1 += `<br>Created:`
            column2 += `<br> ${createLog.username} ${createLog.created_at}`
          }

          for (const updateLog of updateLogs) {
            column1 += `<br>Updated:`
            column2 += `<br> ${updateLog.username} ${updateLog.created_at}`
          }

          $(`.txn-row-${txnId} .attribute_changes .column_1`).html(column1)
          $(`.txn-row-${txnId} .attribute_changes .column_2`).html(column2)
        })
    }
  })

  $(".packing_action_popup_wrapper .close").on("click", function () {
    $(this).parent().parent().hide()
    focusSearch()
  })

  document.querySelector(".print_customs_link")?.addEventListener("click", e => {
    e.preventDefault()
    const print_invoice_popup_wrapper = document.querySelector<HTMLDivElement>(".print_invoice_popup_wrapper")
    if (document.querySelector(".print_invoice_popup") && print_invoice_popup_wrapper) {
      print_invoice_popup_wrapper.style.display = "block"
    } else {
      if (!env.TEST) {
        setTimeout(() => {
          window.frames.customs_print?.window.print()
        }, 200)
      }
    }

    return false
  })

  const popupClasses = [
    "unifaun_action_popup",
    "unifaun_alert_popup",
    "varubrev_alert_popup",
    "deutsche_post_alert_popup",
    "no_bring_alert_popup",
    "customer_pickup_label_popup",
    "postnord_action_popup",
    "swisspost_action_popup",
    "swisspost_alert_popup",
    "bundle_action_popup",
  ]

  popupClasses.forEach(element => {
    const wrapper = document.querySelector<HTMLDivElement>(`.${element}_wrapper`)
    if (document.getElementsByClassName(element).length > 0 && wrapper) {
      wrapper.style.display = "block"

      setTimeout(() => {
        wrapper.style.display = "none"
        focusSearch()
      }, 10000)
    }

    if (document.getElementsByClassName(element).length > 0 && wrapper) {
      Mousetrap.bind("enter", e => {
        if (wrapper.style.display === "block") {
          e.preventDefault()
        }

        wrapper.style.display = "none"
        focusSearch()
      })

      document.querySelector(`.${element}_wrapper .btn`)?.addEventListener("click", () => {
        wrapper.style.display = "none"
        focusSearch()
      })

      wrapper.addEventListener("click", e => {
        const childElem = e.target as HTMLElement
        if (childElem.contains(wrapper)) {
          wrapper.style.display = "none"
          focusSearch()
        }
      })
    }
  })

  if (document.getElementsByClassName("print_invoice_popup").length > 0) {
    const print_invoice_popup_wrapper = document.querySelector<HTMLDivElement>(".print_invoice_popup_wrapper")
    if (print_invoice_popup_wrapper) {
      print_invoice_popup_wrapper.style.display = "block"
      setTimeout(() => {
        print_invoice_popup_wrapper.style.display = "none"
        focusSearch()
      }, 10000)
    }

    document.querySelector(".print_invoice_link")?.addEventListener("click", e => {
      e.preventDefault()
      if (print_invoice_popup_wrapper) {
        print_invoice_popup_wrapper.style.display = "block"
      }

      return false
    })

    document.querySelector(".btn.yes")?.addEventListener("click", () => {
      if (!env.TEST) {
        window.frames.invoice_print?.window.print()
      }
      if (print_invoice_popup_wrapper) {
        print_invoice_popup_wrapper.style.display = "none"
      }
      focusSearch()
    })

    document.querySelector(".btn.no")?.addEventListener("click", () => {
      if (print_invoice_popup_wrapper) {
        print_invoice_popup_wrapper.style.display = "none"
      }
      focusSearch()
    })
    const searchInput = <HTMLInputElement>document.getElementById("find_id")

    Mousetrap.bind(["esc", "enter"], e => {
      if (searchInput && searchInput.value.length > 0) {
        focusSearch()
      } else if (print_invoice_popup_wrapper && print_invoice_popup_wrapper.style.display === "block") {
        print_invoice_popup_wrapper.style.display = "none"
        e.preventDefault()
      }
      focusSearch()
    })
  }

  if (document.getElementsByClassName("multishelf_popup_wrapper").length > 0) {
    const multishelf_popup_wrapper = document.querySelector<HTMLDivElement>(".multishelf_popup_wrapper")
    if (multishelf_popup_wrapper) {
      multishelf_popup_wrapper.style.display = "block"
    }

    setTimeout(() => {
      if (multishelf_popup_wrapper) {
        multishelf_popup_wrapper.style.display = "none"
      }
      focusSearch()
    }, 10000)
    Mousetrap.bind("enter", e => {
      if (multishelf_popup_wrapper) {
        if (multishelf_popup_wrapper.style.display === "block") {
          e.preventDefault()
        }
        multishelf_popup_wrapper.style.display = "none"
      }

      focusSearch()
    })

    document.querySelector(".btn.ok")?.addEventListener("click", () => {
      if (multishelf_popup_wrapper) {
        multishelf_popup_wrapper.style.display = "none"
      }
      focusSearch()
    })
  }

  document.querySelector(".autoweight .partials-btn")?.addEventListener("click", e => {
    e.preventDefault()
    document.querySelector(".autoweight .partials")?.classList.toggle("hidden")
  })

  document.querySelector(".autoweight .items-btn")?.addEventListener("click", e => {
    e.preventDefault()
    document.querySelector(".autoweight .item-partials")?.classList.toggle("hidden")
  })

  document.querySelector(".autoweight .fixmethods-btn")?.addEventListener("click", e => {
    e.preventDefault()
    document.querySelector(".autoweight .fixmethod-partials")?.classList.toggle("hidden")
  })

  document.querySelector(".autoweight .documents-btn")?.addEventListener("click", e => {
    e.preventDefault()
    document.querySelector(".autoweight .document-partials")?.classList.toggle("hidden")
  })

  const openCustomerPickupPopup = () => {
    const customerPickupParams = $("#customer_pickup_params")

    if (customerPickupParams) {
      window.dispatchEvent(
        new CustomEvent("openAdminPopup", { detail: { options: JSON.stringify(customerPickupParams.data("options")) } })
      )
    }
  }

  const localeSelect = document.querySelector<HTMLSelectElement>(".help_locale_select")
  const categorySelect = document.querySelector<HTMLSelectElement>(".help_category_select")

  const enableCategories = () => {
    if (localeSelect && categorySelect) {
      const selectedLocale = (<HTMLOptionElement>localeSelect[localeSelect.selectedIndex]).value
      const options = categorySelect.options
      let correctSelected = options[categorySelect.selectedIndex].dataset.locale == selectedLocale

      for (let i = 0; i < options.length; i++) {
        const option = options[i]

        if (selectedLocale != option.dataset.locale) {
          option.classList.add("hidden")
        } else {
          option.classList.remove("hidden")

          if (!correctSelected) {
            categorySelect.selectedIndex = i
            correctSelected = true
          }
        }
      }
    }
  }

  if (localeSelect) {
    localeSelect.addEventListener("change", enableCategories)
    enableCategories()
  }
})
