import "message-bus-client"
import type { MessageBusClient } from "message-bus-client"
import currentGlobal from "./global"

declare global {
  interface Window {
    MessageBus: MessageBusClient
  }
}

const MessageBus = currentGlobal.MessageBus

// https://github.com/discourse/message_bus/blob/d63f808d71732af78e30a65dcee33e198b8fedc9/assets/message-bus-ajax.js
MessageBus.ajax = function (options) {
  const xhr = new XMLHttpRequest()
  // xhr.responseType = options.dataType
  xhr.open("POST", options.url)
  for (const name in options.headers) {
    xhr.setRequestHeader(name, options.headers[name])
  }
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded")
  if (options.messageBus.chunked) {
    options.messageBus.onProgressListener(xhr)
  }
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      const status = xhr.status
      if ((status >= 200 && status < 300) || status === 304) {
        options.success(xhr.responseText)
      } else {
        options.error(xhr, xhr.statusText)
      }
      options.complete()
    }
  }
  xhr.send(new URLSearchParams(options.data).toString())
  return xhr
}

export default MessageBus
