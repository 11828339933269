import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["text", "submitForm"]

  declare textTarget: HTMLInputElement
  declare hasTextTarget: boolean

  declare submitFormTarget: HTMLButtonElement
  declare hasSubmitFormTarget: boolean

  connect() {
    this.submitFormTarget.disabled = true
  }

  validatePresence(e?: Event) {
    e?.preventDefault()
    this.submitFormTarget.disabled = this.textTarget.value.trim() == ""
  }
}
