import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkedOnly", "selectBox", "limit", "startBatch", "categoryError"]

  declare checkedOnlyTarget: HTMLInputElement
  declare hasCheckedOnlyTarget: boolean

  declare selectBoxTargets: HTMLInputElement[]

  declare limitTarget: HTMLInputElement
  declare hasLimitTarget: boolean

  declare startBatchTarget: HTMLInputElement
  declare hasStartBatchTarget: boolean

  declare categoryErrorTarget: HTMLDivElement
  declare hasCategoryErrorTarget: boolean

  value = 0
  checked = false

  private get checkedOnly() {
    return (this.hasCheckedOnlyTarget && this.checkedOnlyTarget.checked) || !this.hasCheckedOnlyTarget
  }

  private get someBoxesChecked() {
    const checkedBoxes = this.selectBoxTargets.filter(c => c.checked)

    if (this.hasCategoryErrorTarget) {
      if (checkedBoxes.length > 0 && Array.from(new Set(checkedBoxes.map(c => c.closest("table")))).length > 1) {
        this.categoryErrorTarget.classList.remove("hidden")
        return false
      }

      this.categoryErrorTarget.classList.add("hidden")
    }

    return checkedBoxes.length > 0
  }

  connect() {
    this.updateChecked()
    this.updateLimit()
  }

  updateChecked() {
    this.checked = this.checkedOnly && this.someBoxesChecked
    this.checkButton()
  }

  updateLimit() {
    if (this.hasLimitTarget) {
      this.value = parseFloat(this.limitTarget.value)
      this.checkButton()
    }
  }

  private checkButton() {
    if (!this.hasStartBatchTarget) {
      return
    }

    if (this.checked || this.value > 0) {
      this.startBatchTarget.disabled = false
      return
    }

    this.startBatchTarget.disabled = true
  }
}
