import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submit"]

  declare submitTarget: HTMLButtonElement
  declare hasSubmitTarget: boolean

  validateImagesLoaded(e: CustomEvent) {
    if (this.hasSubmitTarget) {
      this.submitTarget.disabled = !e.detail.valid
    }
  }
}
