import $ from "jquery"
import Util from "@/common/util"

document.addEventListener("DOMContentLoaded", () => {
  const addressFields = ["name", "company", "street", "street2", "city", "state", "zipcode", "country", "house_number"]

  const vatQuery = document.querySelector<HTMLButtonElement>("#vatquery")

  vatQuery?.addEventListener("click", e => {
    let vat = vatQuery.getAttribute("data-vat")
    const id = vatQuery.getAttribute("data-id")
    const vatInput = document.querySelector<HTMLInputElement>("#order_vat_id")

    if (vatInput) {
      vat = vatInput.value
    }

    const form = document.createElement("form")
    form.action = "/admin/orders/vat_check?vat=" + vat + "&id=" + id
    form.method = "POST"
    const csrfmeta = document.querySelector<HTMLMetaElement>('meta[name="csrf-token"]')
    if (csrfmeta) {
      const csrfinput = document.createElement("input")
      csrfinput.id = "authenticity_token"
      csrfinput.name = "authenticity_token"
      csrfinput.value = csrfmeta.content
      form.appendChild(csrfinput)
    }
    document.body.appendChild(form)
    form.submit()

    e.preventDefault()
  })

  if (vatQuery) {
    vatQuery.dataset.loaded = "true"
  }

  const orderPaymentMethod = document.querySelector<HTMLSelectElement>("#order_payment_method")

  orderPaymentMethod?.addEventListener("change", e => {
    const value = (e.target as HTMLSelectElement).value
    const offerInvoiceEmail = document.querySelector<HTMLElement>("#offer_invoice_email")
    if (value === "10000") {
      offerInvoiceEmail?.classList.remove("hidden")
    } else {
      offerInvoiceEmail?.classList.add("hidden")
    }
  })

  const orderEmail = document.querySelector<HTMLInputElement>("#order_email")

  orderEmail?.addEventListener("input", () => {
    const email = orderEmail?.value
    const submit = <HTMLInputElement>document.querySelector("#offer_email_submit")

    if (email) {
      const valid = email.match(Util.validations.email)
      submit.disabled = !valid
    }
  })

  const importAddress = document.querySelector<HTMLButtonElement>("#import_address")
  const cancelImportAddress = document.getElementById("cancel_import_address")

  importAddress?.addEventListener("click", e => {
    const customerData = importAddress.getAttribute("data-customer")
    if (customerData) {
      const customer = JSON.parse(customerData)

      for (const key of addressFields) {
        const invoiceInput = document.querySelector<HTMLInputElement>("#order_invoice_" + key)
        if (invoiceInput) {
          invoiceInput.value = customer[key]
        }
      }
    }

    if (cancelImportAddress) {
      cancelImportAddress.style.display = "block"
    }

    e.preventDefault()
  })

  cancelImportAddress?.addEventListener("click", e => {
    const orderData = cancelImportAddress.getAttribute("data-order")
    if (orderData) {
      const order = JSON.parse(orderData)
      for (const key of addressFields) {
        const invoiceInput = document.querySelector<HTMLInputElement>("#order_invoice_" + key)
        if (invoiceInput) {
          invoiceInput.value = order["invoice_" + key]
        }
      }
    }

    cancelImportAddress.style.display = "none"
    e.preventDefault()
  })

  const importDeliveryAddress = document.querySelector<HTMLButtonElement>("#import_delivery_address")
  const cancelImportDeliveryAddress = document.getElementById("cancel_import_delivery_address")

  importDeliveryAddress?.addEventListener("click", e => {
    const customerData = importDeliveryAddress.getAttribute("data-customer")
    if (customerData) {
      const customer = JSON.parse(customerData)

      for (const key of addressFields) {
        const deliveryInput = document.querySelector<HTMLInputElement>("#order_" + key)
        if (deliveryInput) {
          deliveryInput.value = customer["delivery_" + key]
        }
      }
    }

    if (cancelImportDeliveryAddress) {
      cancelImportDeliveryAddress.style.display = "block"
    }

    e.preventDefault()
  })

  cancelImportDeliveryAddress?.addEventListener("click", e => {
    const orderData = cancelImportDeliveryAddress.getAttribute("data-order")
    if (orderData) {
      const order = JSON.parse(orderData)
      for (const key of addressFields) {
        const deliveryInput = document.querySelector<HTMLInputElement>("#order_" + key)
        if (deliveryInput) {
          deliveryInput.value = order[key]
        }
      }
    }

    cancelImportDeliveryAddress.style.display = "none"
    e.preventDefault()
  })

  const unifaunUpdate = document.querySelector<HTMLButtonElement>("#unifaun-update")
  const unifaunShow = document.querySelector<HTMLSpanElement>("#unifaun-show")
  const unifaunForm = document.querySelector<HTMLSpanElement>("#unifaun-form")
  const unifaunSave = document.querySelector<HTMLButtonElement>("#unifaun-save")
  const unifaunId = document.querySelector<HTMLInputElement>("#unifaun_id")

  unifaunUpdate?.addEventListener("click", () => {
    if (unifaunShow && unifaunForm) {
      unifaunShow.style.display = unifaunShow.style.display === "none" ? "block" : "none"
      unifaunForm.style.display = unifaunForm.style.display === "none" ? "block" : "none"
    }
  })

  unifaunSave?.addEventListener("click", () => {
    const id = unifaunSave.getAttribute("data-id")
    const unifaunIdValue = unifaunId?.value
    if (!unifaunIdValue) {
      return alert("unifaun ID field not found")
    }

    const data = { order: { unifaun_id: unifaunIdValue } }

    $.ajax({
      url: `/admin/orders/${id}`,
      type: "PUT",
      dataType: "json",
      data,
    }).done(data => {
      const unifaunShowSpan = unifaunShow?.querySelector("span")
      if (unifaunShowSpan) {
        unifaunShowSpan.innerHTML = data.unifaun_url
          ? `<a href="${data.unifaun_url}">${unifaunIdValue}</a>`
          : unifaunIdValue
      }

      if (unifaunShow && unifaunForm) {
        unifaunShow.style.display = "block"
        unifaunForm.style.display = "none"
      }
    })
  })

  const reclaimImageUploadLink = document.querySelector<HTMLElement>("#reclaim_image_upload_link")

  reclaimImageUploadLink?.addEventListener("click", e => {
    e.preventDefault()
    const reclaimImageUploadWrapper = document.querySelector<HTMLDivElement>(".reclaim_image_upload_popup_wrapper")
    if (reclaimImageUploadWrapper) {
      reclaimImageUploadWrapper.style.display = "block"
    }
  })

  const copyPasswordLink = document.querySelector<HTMLButtonElement>("#copy_password_link")

  copyPasswordLink?.addEventListener("click", e => {
    e.preventDefault()
    const pswUrl = document.querySelector<HTMLInputElement>("#create_password_url")
    if (pswUrl) {
      navigator.clipboard.writeText(pswUrl.value)
    }
  })

  const cancelOfferPopupDone = document.querySelector<HTMLInputElement>(".failed_offer_popup input[type=submit]")
  const cancelOfferMessage = document.querySelector<HTMLInputElement>(".failed_offer_popup input[type=text]")
  const cancelOfferSelectOther = document.querySelector<HTMLInputElement>(
    ".failed_offer_popup #order_failed_offer_reason_other"
  )
  const cancelOfferInputs = document.querySelectorAll<HTMLInputElement>(
    ".failed_offer_popup input[type=radio], .failed_offer_popup input[type=text]"
  )

  for (const input of Array.from(cancelOfferInputs)) {
    input.addEventListener("change", () => {
      if (cancelOfferSelectOther?.checked && cancelOfferMessage?.value == "" && cancelOfferPopupDone) {
        cancelOfferPopupDone.disabled = true
      } else if (cancelOfferPopupDone) {
        cancelOfferPopupDone.disabled = false
      }
    })
  }

  const cancelOfferButton = document.querySelector("#set_offer_failed")
  const failedOfferPopupWrapper = document.querySelector<HTMLElement>(".failed_offer_popup_wrapper")
  const closeFailedOfferPopup = document.querySelector(".failed_offer_popup_wrapper .close")

  for (const element of [cancelOfferButton, closeFailedOfferPopup]) {
    element?.addEventListener("click", e => {
      e.preventDefault()
      toggleFailedOfferWrapper()
    })
  }

  failedOfferPopupWrapper?.addEventListener("click", e => {
    if ((e.target as HTMLElement).classList.contains("failed_offer_popup_wrapper")) {
      e.preventDefault()
      toggleFailedOfferWrapper()
    }
  })

  const toggleFailedOfferWrapper = () => {
    if (failedOfferPopupWrapper) {
      if (failedOfferPopupWrapper.style.display == "none") {
        failedOfferPopupWrapper.style.display = "block"
      } else {
        failedOfferPopupWrapper.style.display = "none"
      }
    }
  }
})
