import storageLocal from "@/common/storage"

const orderCheckboxes = () =>
  Array.from(document.querySelectorAll("table.process-orders td input[type=checkbox]") as NodeListOf<HTMLInputElement>)

const toggleCode = (id: string, show: boolean) => {
  const codes = document.querySelectorAll(`.signcodes .code-${id}`)

  for (const code of Array.from(codes)) {
    if (show) {
      code.classList.remove("hidden")
    } else {
      code.classList.add("hidden")
    }
  }
}

const rememberBoxes = () => {
  const checkedIds = orderCheckboxes()
    .filter(c => c.checked)
    .map(c => c.value)

  storageLocal.setItem("plastic_redo_checked", JSON.stringify(checkedIds))
}

const restoreBoxes = () => {
  const json = storageLocal.getItem("plastic_redo_checked")

  if (json) {
    const checkedIds = JSON.parse(json) as string[]

    for (const checkbox of orderCheckboxes()) {
      if (checkedIds.includes(checkbox.value)) {
        checkbox.checked = true
        toggleCode(checkbox.value, true)
      }
    }
  }
}

const plasticRedo = () => {
  restoreBoxes()

  const elements = document.querySelectorAll(
    "table.process-orders input[type=checkbox]"
  ) as NodeListOf<HTMLInputElement>

  for (const el of Array.from(elements)) {
    el.addEventListener("change", () => {
      if (el.classList.contains("check_all_select")) {
        for (const subbox of Array.from(
          el.closest("table.process-orders")?.querySelectorAll("td input[type=checkbox]") || []
        )) {
          const checkbox = subbox as HTMLInputElement
          toggleCode(checkbox.value, checkbox.checked)
        }
      } else {
        toggleCode(el.value, el.checked)
      }

      rememberBoxes()
    })
  }
}

const toggleRedoBtn = (box: HTMLInputElement) => {
  const table = box.closest("table.process-orders")
  const boxes = Array.from(table?.querySelectorAll("td input[type=checkbox]") as NodeListOf<HTMLInputElement>)

  const btnRow = table?.querySelector(".redo-button-row")

  if (!btnRow) {
    return
  }

  if (boxes.find(b => b.checked)) {
    btnRow.classList.remove("hidden")
  } else {
    btnRow.classList.add("hidden")
  }
}

const printRedo = () => {
  const elements = document.querySelectorAll(
    "table.process-orders input[type=checkbox]"
  ) as NodeListOf<HTMLInputElement>

  for (const el of Array.from(elements)) {
    el.addEventListener("change", () => {
      toggleRedoBtn(el)
    })
  }

  for (const btn of Array.from(
    document.querySelectorAll("table.process-orders .redo-button") as NodeListOf<HTMLButtonElement>
  )) {
    btn.addEventListener("click", () => {
      const table = btn.closest("table.process-orders")
      const boxes = Array.from(table?.querySelectorAll("td input[type=checkbox]") as NodeListOf<HTMLInputElement>)
      const ids = boxes.filter(c => c.checked).map(c => c.value)

      // Prevent accidental double clicks
      if (btn.dataset.disabled) {
        return
      }

      btn.dataset.disabled = "true"
      const form = document.getElementById("redo_todo_action") as HTMLFormElement
      const idfield = form.querySelector(".ids") as HTMLInputElement
      idfield.value = ids.join(",")

      form.submit()
    })
  }
}

document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelectorAll(".process-orders.plastic-redo").length > 0) {
    plasticRedo()
  }

  if (document.querySelectorAll(".process-orders.print-redo").length > 0) {
    printRedo()
  }
})
