function checkAllUpdate(e: Event) {
  if (e.target instanceof HTMLInputElement) {
    const table = e.target.closest(".process-orders")

    if (table instanceof HTMLTableElement) {
      for (const checkbox of Array.from(table.querySelectorAll("input[type=checkbox]"))) {
        if (checkbox instanceof HTMLInputElement) {
          checkbox.checked = e.target.checked
        }
      }
    }
  }
}

document.addEventListener("click", e => {
  if (e.target instanceof HTMLElement) {
    if (e.target.matches(".check_all_select, .check_all_ignore")) {
      checkAllUpdate(e)
    }
  }
})
