import tinymce from "tinymce"
import "tinymce/themes/silver"
import "tinymce/plugins/hr"
import "tinymce/plugins/link"
import "tinymce/plugins/charmap"
import "tinymce/plugins/code"
import "tinymce/plugins/lists"
import "tinymce/plugins/table"
import "tinymce/plugins/paste"
import "tinymce/plugins/image"

export const initEditor = () => {
  tinymce.init({
    selector: ".tinymce",
    plugins: ["hr", "link", "charmap", "code", "lists", "table", "paste", "image"],
    toolbar:
      "bold italic underline styleselect image | alignleft aligncenter alignright alignjustify | bullist | hr | cut copy paste | removeformat undo redo | link unlink | charmap code",
    relative_urls: false,
    skin_url: "/tinymce/skins/ui/oxide",
    style_formats: [
      { title: "Text regular", block: "p" },
      { title: "Text small", block: "p", classes: "paragraph-sm" },
      { title: "Text extra small", block: "p", classes: "paragraph-xs" },
      { title: "Text micro", block: "p", classes: "paragraph-2xs" },
      { title: "Text no-max-width", block: "p", classes: "no-max-width" },
      { title: "Heading 2", block: "h2" },
      { title: "Heading 3", block: "h3" },
      { title: "Heading 4", block: "h4" },
      { title: "Heading 5", block: "h5" },
      { title: "Heading 6", block: "h6" },
    ],
    content_css: window.tinymce_css || [],
    table_row_class_list: [
      { title: "Regular", value: "" },
      { title: "Light gray", value: "odd" },
      { title: "Gray", value: "even" },
    ],
    forced_root_block: "",
    image_class_list: [
      { title: "Regular", value: "" },
      { title: "Full width", value: "object-contain w-full" },
    ],
  })
}

export const resetEditor = () => {
  tinymce.remove()
  initEditor()
}
