import { Controller } from "@hotwired/stimulus"
import * as WebAuthnJSON from "@github/webauthn-json"
import fetch from "@/common/fetch"
import type { PublicKeyCredentialRequestOptionsJSON } from "@github/webauthn-json/dist/types/basic/json"
import SentryReact from "@/common/sentry_client"

export default class extends Controller {
  static values = { options: Object, url: String }

  declare optionsValue: PublicKeyCredentialRequestOptionsJSON
  declare hasOptionsValue: boolean

  declare urlValue: string
  declare hasUrlValue: boolean

  static targets = ["spinner", "error", "submit"]

  declare spinnerTarget: HTMLDivElement
  declare hasSpinnerTarget: boolean

  declare errorTarget: HTMLDivElement
  declare hasErrorTarget: boolean

  declare submitTarget: HTMLButtonElement
  declare hasSubmitTarget: boolean

  verify(e?: Event) {
    e?.preventDefault()

    if (!this.hasOptionsValue || !this.hasUrlValue) {
      return
    }

    if (this.hasSubmitTarget) {
      this.submitTarget.classList.add("hidden")
    }

    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.remove("hidden")
    }

    if (this.hasErrorTarget) {
      this.errorTarget.classList.add("hidden")
    }

    WebAuthnJSON.get({ publicKey: this.optionsValue })
      .then(
        success => this.callback(this.urlValue, success),
        error => {
          SentryReact.captureMessage(error)
          this.error("Registration rejected.")
        }
      )
      .catch(error => {
        SentryReact.captureMessage(error)
        this.error("Your security key reported an error.")
      })
  }

  callback(url: string, body: WebAuthnJSON.PublicKeyCredentialWithAssertionJSON) {
    fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "same-origin",
    }).then(function (response) {
      if (response.ok) {
        response.json().then(resp => window.location.replace(resp.location))
      } else if (response.status === 403) {
        window.location.reload()
      } else if (response.status < 500) {
        response.text().then(error => {
          SentryReact.captureMessage(error)
          this.error(error)
        })
      } else {
        this.error("Error verifying security key.")
      }
    })
  }

  error(message: string) {
    if (this.hasErrorTarget) {
      this.errorTarget.classList.remove("hidden")
      this.errorTarget.innerText = `Error: ${message}`
    }

    if (this.hasSubmitTarget) {
      this.submitTarget.innerText = "Try again"
      this.submitTarget.classList.remove("hidden")
    }

    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.add("hidden")
    }
  }
}
