import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  openAdminPopup(e?: Event) {
    e?.preventDefault()

    window.dispatchEvent(
      new CustomEvent("openAdminPopup", { detail: { options: (this.element as HTMLElement).dataset["options"] } })
    )
  }
}
