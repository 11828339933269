import moment from "moment"
import $ from "jquery"
import Rails from "@rails/ujs"

try {
  Rails.start()
} catch (e) {
  // Rails already started
}

declare global {
  interface Window {
    moment?: typeof moment
    $?: JQueryStatic
    jQuery?: JQueryStatic
  }
}

window.moment = moment
window.$ = window.jQuery = $

$.ajaxPrefilter((options, originalOptions, xhr) => {
  if (!options.crossDomain) {
    Rails.CSRFProtection(xhr)
  }
})
