import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["deliveryTimeFrom", "deliveryTimeTo", "deliveryTimeInvalid"]

  declare deliveryTimeFromTarget: HTMLInputElement
  declare hasDeliveryTimeFromTarget: boolean

  declare deliveryTimeToTarget: HTMLInputElement
  declare hasDeliveryTimeToTarget: boolean

  declare deliveryTimeInvalidTarget: HTMLDivElement
  declare hasDeliveryTimeInvalidTarget: boolean

  checkDeliveryValid(e?: Event) {
    if (
      this.deliveryTimeFromTarget.value == "" ||
      this.deliveryTimeToTarget.value == "" ||
      parseInt(this.deliveryTimeFromTarget.value) > parseInt(this.deliveryTimeToTarget.value)
    ) {
      e?.preventDefault()

      this.deliveryTimeInvalidTarget.classList.remove("hidden")
    }
  }
}
