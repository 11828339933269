import { Controller } from "@hotwired/stimulus"
import api from "@/common/util/api"
import { draggable } from "@/common/draggable"
import { addErrorMsgOnInput } from "@/common/util"
import type { InputErrors } from "@/common/util"

const newSignTagRowTemplate = (pagename: string, url: string, tag: string, id: string, full_url: string) => {
  return `
  <tr class="sortable" data-action="dragend->sign-category#handleDragEnd" data-id="${id}" data-pagename="${pagename}" data-sign-category-target="signTagRow" draggable="true" id="sign_tag-${id}">
    <td>
      <div class="flex items-center">
        <div class="mr-2">
          <svg class="h-5 w-5" fill="currentColor"><path d="M7 6C6.46957 6 5.96086 5.78929 5.58579 5.41421C5.21071 5.03914 5 4.53043 5 4C5 3.46957 5.21071 2.96086 5.58579 2.58579C5.96086 2.21071 6.46957 2 7 2C7.53043 2 8.03914 2.21071 8.41421 2.58579C8.78929 2.96086 9 3.46957 9 4C9 4.53043 8.78929 5.03914 8.41421 5.41421C8.03914 5.78929 7.53043 6 7 6ZM7 12C6.46957 12 5.96086 11.7893 5.58579 11.4142C5.21071 11.0391 5 10.5304 5 10C5 9.46957 5.21071 8.96086 5.58579 8.58579C5.96086 8.21071 6.46957 8 7 8C7.53043 8 8.03914 8.21071 8.41421 8.58579C8.78929 8.96086 9 9.46957 9 10C9 10.5304 8.78929 11.0391 8.41421 11.4142C8.03914 11.7893 7.53043 12 7 12ZM7 18C6.46957 18 5.96086 17.7893 5.58579 17.4142C5.21071 17.0391 5 16.5304 5 16C5 15.4696 5.21071 14.9609 5.58579 14.5858C5.96086 14.2107 6.46957 14 7 14C7.53043 14 8.03914 14.2107 8.41421 14.5858C8.78929 14.9609 9 15.4696 9 16C9 16.5304 8.78929 17.0391 8.41421 17.4142C8.03914 17.7893 7.53043 18 7 18Z" /><path d="M13 6C12.4696 6 11.9609 5.78929 11.5858 5.41421C11.2107 5.03914 11 4.53043 11 4C11 3.46957 11.2107 2.96086 11.5858 2.58579C11.9609 2.21071 12.4696 2 13 2C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4C15 4.53043 14.7893 5.03914 14.4142 5.41421C14.0391 5.78929 13.5304 6 13 6ZM13 12C12.4696 12 11.9609 11.7893 11.5858 11.4142C11.2107 11.0391 11 10.5304 11 10C11 9.46957 11.2107 8.96086 11.5858 8.58579C11.9609 8.21071 12.4696 8 13 8C13.5304 8 14.0391 8.21071 14.4142 8.58579C14.7893 8.96086 15 9.46957 15 10C15 10.5304 14.7893 11.0391 14.4142 11.4142C14.0391 11.7893 13.5304 12 13 12ZM13 18C12.4696 18 11.9609 17.7893 11.5858 17.4142C11.2107 17.0391 11 16.5304 11 16C11 15.4696 11.2107 14.9609 11.5858 14.5858C11.9609 14.2107 12.4696 14 13 14C13.5304 14 14.0391 14.2107 14.4142 14.5858C14.7893 14.9609 15 15.4696 15 16C15 16.5304 14.7893 17.0391 14.4142 17.4142C14.0391 17.7893 13.5304 18 13 18Z" /></svg>
        </div>
        <a class="font-medium overflow-ellipsis overflow-hidden" draggable="false" href="/admin/sign_tags/${id}/edit">
          ${pagename}
        </a>
      </div>
    </td>
    <td class="overflow-ellipsis overflow-hidden">${url}</td>
    <td class="overflow-ellipsis overflow-hidden">${tag}</td>
    <td class="text-right text-sm space-x-1">
      <a class="text-indigo-600 hover:text-indigo-900" draggable="false" href="/admin/sign_tags/${id}/edit">Edit</a>
      <a class="text-indigo-600 hover:text-indigo-900" draggable="false" href="${full_url}">View page</a>
      <button class="unlink text-red-600" data-action="sign-category#deleteSignTagConnection" draggable="false">Unlink</button>
    </td>
  </tr>
`
}

interface LocaleData {
  locale: string
  name: string
}

export default class extends Controller {
  static targets = [
    "unsavedChanges",
    "signCategoryForm",
    "categoryStatus",
    "categoryName",
    "categoryPath",
    "categoryTitle",
    "categorySubtitle",
    "categoryRelatedPagesTitle",
    "signTagsTable",
    "signTagRow",
    "submit",
    "locale",
    "localeName",
    "submitCreateCopies",
    "roleBusiness",
    "linkedBusinessCategory",
    "linkedConsumerCategory",
    "businessCategoriesWrapper",
    "consumerCategoriesWrapper",
  ]

  declare unsavedChangesTarget: HTMLDivElement
  declare hasUnsavedChangesTarget: boolean

  declare signCategoryFormTarget: HTMLDivElement
  declare hasSignCategoryFormTarget: boolean

  declare categoryStatusTarget: HTMLInputElement
  declare hasCategoryStatusTarget: boolean

  declare categoryNameTarget: HTMLInputElement
  declare hasCategoryNameTarget: boolean

  declare categoryPathTarget: HTMLInputElement
  declare hasCategoryPathTarget: boolean

  declare categoryTitleTarget: HTMLInputElement
  declare hasCategoryTitleTarget: boolean

  declare categorySubtitleTarget: HTMLInputElement
  declare hasCategorySubtitleTarget: boolean

  declare categoryRelatedPagesTitleTarget: HTMLInputElement
  declare hasCategoryRelatedPagesTitleTarget: boolean

  declare signTagsTableTarget: HTMLTableElement
  declare hasSignTagsTableTarget: boolean

  declare signTagRowTargets: HTMLDivElement[]

  declare submitTarget: HTMLButtonElement
  declare hasSubmitTarget: boolean

  declare localeTargets: HTMLInputElement[]
  declare localeNameTargets: HTMLInputElement[]

  declare submitCreateCopiesTarget: HTMLButtonElement
  declare hasSubmitCreateCopiesTarget: boolean

  declare roleBusinessTarget: HTMLInputElement
  declare hasRoleBusinessTarget: boolean

  declare businessCategoriesWrapperTarget: HTMLDivElement
  declare hasBusinessCategoriesWrapperTarget: boolean

  declare linkedBusinessCategoryTarget: HTMLInputElement
  declare hasLinkedBusinessCategoryTarget: boolean

  declare consumerCategoriesWrapperTarget: HTMLDivElement
  declare hasConsumerCategoriesWrapperTarget: boolean

  declare linkedConsumerCategoryTarget: HTMLInputElement
  declare hasLinkedConsumerCategoryTarget: boolean

  initStatus: boolean
  initBusiness: boolean
  initName: string
  initPath: string
  initTitle: string
  initSubtitle: string
  initRelatedPagesTitle: string
  initLinkedBusinessCategory: string
  initLinkedConsumerCategory: string
  initIds: string[] = []
  currentIds: string[] = []
  deleteIds: string[] = []

  connect() {
    this.initStatus = this.categoryStatusTarget.checked
    this.initName = this.categoryNameTarget.value
    this.initPath = this.categoryPathTarget.value
    this.initTitle = this.categoryTitleTarget.value
    this.initSubtitle = this.categorySubtitleTarget.value
    this.initRelatedPagesTitle = this.categoryRelatedPagesTitleTarget.value
    this.initBusiness = this.roleBusinessTarget.checked
    this.initIds = this.currentSignTagIds()
    this.currentIds = this.currentSignTagIds()
    this.initLinkedBusinessCategory = this.linkedBusinessCategoryTarget.value
    this.initLinkedConsumerCategory = this.linkedConsumerCategoryTarget.value

    draggable(this.signTagsTableTarget)

    this.toggleLinkedCategories()

    Promise.resolve().then(() => {
      window.dispatchEvent(new CustomEvent("disableComboboxOptions", { detail: { ids: this.initIds } }))
    })
  }

  addSignTagConnection(e?: Event) {
    e?.preventDefault()

    const selected = e?.currentTarget as HTMLDivElement

    this.signTagsTableTarget.insertAdjacentHTML(
      "beforeend",
      newSignTagRowTemplate(
        selected.dataset.pagename || "",
        selected.dataset.url || "",
        selected.dataset.value || "",
        selected.dataset.id || "",
        selected.dataset.full_url || ""
      )
    )

    window.dispatchEvent(new CustomEvent("disableComboboxOptions", { detail: { ids: [selected.dataset.id] } }))
    draggable(this.signTagsTableTarget)
    this.checkChanges()
    this.currentIds = this.currentSignTagIds()

    window.dispatchEvent(new CustomEvent("clearCombobox"))
  }

  deleteSignTagConnection(e?: Event) {
    e?.preventDefault()

    const target = e?.currentTarget as HTMLElement
    const row = target.closest("tr")

    if (row && row.dataset.id) {
      const id = row.dataset.id
      this.deleteIds.push(id)
      row.remove()

      window.dispatchEvent(new CustomEvent("enableComboboxOptions", { detail: { ids: [id] } }))
    }

    this.checkChanges()
    this.currentIds = this.currentSignTagIds()
  }

  sortRows(e?: Event) {
    e?.preventDefault()

    window.dispatchEvent(
      new CustomEvent("sortByValues", { detail: { initial: this.currentIds, param1: "pagename", param2: "id" } })
    )

    this.checkChanges()
  }

  handleDragEnd(e?: Event) {
    e?.preventDefault()

    this.currentIds = this.currentSignTagIds()

    this.checkChanges()
  }

  toggleLinkedCategories(e?: Event) {
    e?.preventDefault()

    if (this.roleBusinessTarget.checked) {
      this.businessCategoriesWrapperTarget.classList.add("hidden")
      this.consumerCategoriesWrapperTarget.classList.remove("hidden")
    } else {
      this.businessCategoriesWrapperTarget.classList.remove("hidden")
      this.consumerCategoriesWrapperTarget.classList.add("hidden")
    }
  }

  checkChanges(e?: Event) {
    e?.preventDefault()

    const currentIds = this.currentSignTagIds()

    const changes =
      JSON.stringify(this.initIds) !== JSON.stringify(currentIds) ||
      this.initStatus !== this.categoryStatusTarget.checked ||
      this.initBusiness !== this.roleBusinessTarget.checked ||
      this.initName !== this.categoryNameTarget.value ||
      this.initPath !== this.categoryPathTarget.value ||
      this.initTitle !== this.categoryTitleTarget.value ||
      this.initSubtitle !== this.categorySubtitleTarget.value ||
      this.initRelatedPagesTitle !== this.categoryRelatedPagesTitleTarget.value ||
      this.initLinkedBusinessCategory !== this.linkedBusinessCategoryTarget.value ||
      this.initLinkedConsumerCategory !== this.linkedConsumerCategoryTarget.value

    this.setHasChanges(changes)
  }

  save(e?: Event) {
    e?.preventDefault()
    this.signCategoryFormTarget.classList.add("saving")
    this.signTagsTableTarget.classList.add("saving")

    const updateUrl = this.submitTarget.dataset.updateUrl

    if (updateUrl) {
      api
        .patch(updateUrl, this.categoryParams())
        .then(response => {
          if (response.ok) {
            window.location.reload()
          } else if (response.errors) {
            this.addErrors(response.errors)

            this.signCategoryFormTarget.classList.remove("saving")
            this.signTagsTableTarget.classList.remove("saving")
          }
        })
        .catch(e => {
          alert(e)
        })
    }
  }

  create(e?: Event) {
    e?.preventDefault()
    this.signCategoryFormTarget.classList.add("saving")
    this.signTagsTableTarget.classList.add("saving")

    const createUrl = this.submitTarget.dataset.createUrl

    if (createUrl) {
      api
        .post(createUrl, this.categoryParams())
        .then(response => {
          if (response.ok) {
            window.location = response.location
          } else if (response.errors) {
            this.addErrors(response.errors)

            this.signCategoryFormTarget.classList.remove("saving")
            this.signTagsTableTarget.classList.remove("saving")
          }
        })
        .catch(e => {
          alert(e)
        })
    }
  }

  updateSelectedLocales(e?: Event) {
    e?.preventDefault()

    let disabled = false

    const selected = this.selectedLocales()

    if (selected.length > 0) {
      for (const localeTarget of selected) {
        if (this.localeNameTargets[this.localeTargets.indexOf(localeTarget)].value == "") {
          disabled = true
        }
      }
    } else {
      disabled = true
    }

    this.submitCreateCopiesTarget.disabled = disabled
  }

  createCopies(e?: Event) {
    e?.preventDefault()
    this.submitCreateCopiesTarget.disabled = true

    const updateUrl = this.submitCreateCopiesTarget.dataset.updateUrl

    if (updateUrl) {
      api
        .post(updateUrl, {
          status: this.categoryStatusTarget.checked ? 1 : 0,
          sign_tag_ids: this.currentSignTagIds(),
          locale_data: this.selectedLocaleData(),
        })
        .then(() => {
          window.location.reload()
        })
        .catch(e => {
          alert(e)
        })
    }
  }

  currentSignTagIds() {
    return this.signTagRowTargets.map(signTagRow => signTagRow.dataset.id || "")
  }

  selectedLocales() {
    return this.localeTargets.filter(checkbox => checkbox.checked && !checkbox.disabled)
  }

  selectedLocaleData() {
    const array: LocaleData[] = []

    for (const localeTarget of this.selectedLocales()) {
      array.push({
        name: this.localeNameTargets[this.localeTargets.indexOf(localeTarget)].value,
        locale: localeTarget.value,
      })
    }

    return array
  }

  setHasChanges(changes: boolean) {
    this.submitTarget.disabled = !changes

    if (changes) {
      this.unsavedChangesTarget.classList.remove("hidden")
    } else {
      this.unsavedChangesTarget.classList.add("hidden")
    }
  }

  addErrors(errors: InputErrors) {
    if (errors["path_segment"] && this.hasCategoryPathTarget) {
      addErrorMsgOnInput(this.categoryPathTarget, errors["path_segment"])
    }
    if (errors["title"] && this.hasCategoryTitleTarget) {
      addErrorMsgOnInput(this.categoryTitleTarget, errors["title"])
    }
  }

  private categoryParams() {
    return {
      business: this.roleBusinessTarget.checked ? 1 : 0,
      status: this.categoryStatusTarget.checked ? 1 : 0,
      name: this.categoryNameTarget.value,
      path_segment: this.categoryPathTarget.value,
      title: this.categoryTitleTarget.value,
      subtitle: this.categorySubtitleTarget.value,
      related_pages_title: this.categoryRelatedPagesTitleTarget.value,
      linked_category: this.roleBusinessTarget.checked
        ? this.linkedConsumerCategoryTarget.value
        : this.linkedBusinessCategoryTarget.value,
      sign_tag_ids: this.currentSignTagIds(),
      delete_ids: this.deleteIds,
    }
  }
}
