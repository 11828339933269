import { Controller } from "@hotwired/stimulus"
import { showStreet2, streetNrRequired } from "@/common/locales"
import validations from "../../common/util/validations"

export default class extends Controller {
  static values = {
    country: String,
    deliveryCountry: String,
    invoiceCompany: String,
  }

  declare countryValue: string
  declare hasCountryValue: boolean

  declare deliveryCountryValue: string
  declare hasDeliveryCountryValue: boolean

  declare invoiceCompanyValue: string
  declare hasInvoiceCompanyValue: boolean

  static targets = [
    "houseNumber",
    "stateAU",
    "stateUS",
    "state",
    "country",
    "deliveryFirstName",
    "deliveryName",
    "deliveryStreet",
    "deliveryHouseNumber",
    "deliveryCity",
    "deliveryStateAU",
    "deliveryStateUS",
    "deliveryState",
    "deliveryCountry",
    "deliveryZipcode",
    "houseNumberDiv",
    "deliveryHouseNumberDiv",
    "topAddressTitle",
    "differentDeliveryAddress",
    "differentDeliveryForm",
    "vatId",
    "vatIdError",
    "vatCheck",
    "deliveryPhone",
    "phone1",
    "street2Div",
    "street2",
    "deliveryStreet2Div",
    "deliveryStreet2",
  ]

  // invoice address inputs
  declare houseNumberTarget: HTMLInputElement
  declare hasHouseNumberTarget: boolean

  declare stateAUTarget: HTMLInputElement
  declare hasStateAUTarget: boolean

  declare stateUSTarget: HTMLInputElement
  declare hasStateUSTarget: boolean

  declare stateTarget: HTMLInputElement
  declare hasStateTarget: boolean

  declare countryTarget: HTMLInputElement
  declare hasCountryTarget: boolean

  declare phone1Target: HTMLInputElement
  declare hasPhone1Target: boolean

  declare street2Target: HTMLInputElement
  declare hasStreet2Target: boolean

  // delivery address inputs
  declare deliveryFirstNameTarget: HTMLInputElement
  declare hasDeliveryFirstNameTarget: boolean

  declare deliveryNameTarget: HTMLInputElement
  declare hasDeliveryNameTarget: boolean

  declare deliveryStreetTarget: HTMLInputElement
  declare hasDeliveryStreetTarget: boolean

  declare deliveryHouseNumberTarget: HTMLInputElement
  declare hasDeliveryHouseNumberTarget: boolean

  declare deliveryCityTarget: HTMLInputElement
  declare hasDeliveryCityTarget: boolean

  declare deliveryStateAUTarget: HTMLInputElement
  declare hasDeliveryStateAUTarget: boolean

  declare deliveryStateUSTarget: HTMLInputElement
  declare hasDeliveryStateUSTarget: boolean

  declare deliveryStateTarget: HTMLInputElement
  declare hasDeliveryStateTarget: boolean

  declare deliveryCountryTarget: HTMLInputElement
  declare hasDeliveryCountryTarget: boolean

  declare deliveryZipcodeTarget: HTMLInputElement
  declare hasDeliveryZipcodeTarget: boolean

  declare deliveryPhoneTarget: HTMLInputElement
  declare hasDeliveryPhoneTarget: boolean

  declare deliveryStreet2Target: HTMLInputElement
  declare hasDeliveryStreet2Target: boolean

  // wrappers
  declare houseNumberDivTarget: HTMLDivElement
  declare hasHouseNumberDivTarget: boolean

  declare deliveryHouseNumberDivTarget: HTMLDivElement
  declare hasDeliveryHouseNumberDivTarget: boolean

  declare street2DivTarget: HTMLDivElement
  declare hasStreet2DivTarget: boolean

  declare deliveryStreet2DivTarget: HTMLDivElement
  declare hasDeliveryStreet2DivTarget: boolean

  // varia
  declare topAddressTitleTarget: HTMLDivElement
  declare hasTopAddressTitleTarget: boolean

  declare differentDeliveryAddressTarget: HTMLInputElement
  declare hasDifferentDeliveryAddressTarget: boolean

  declare differentDeliveryFormTarget: HTMLDivElement
  declare hasDifferentDeliveryFormTarget: boolean

  declare vatIdTarget: HTMLInputElement
  declare hasVatIdTarget: boolean

  declare vatIdErrorTarget: HTMLDivElement
  declare hasVatIdErrorTarget: boolean

  declare vatCheckTarget: HTMLButtonElement
  declare hasVatCheckTarget: boolean

  connect() {
    this.updateCountry()
    this.updateDeliveryCountry()
    this.toggleDelivery()
    this.validateVat()
  }

  updateCountry(e?: Event) {
    e?.preventDefault()

    let country
    if (this.hasCountryTarget) {
      country = this.countryTarget.value
    } else {
      country = this.countryValue
    }

    if (country && this.hasHouseNumberDivTarget) {
      if (streetNrRequired(country) || this.houseNumberTarget.value.length > 0) {
        this.houseNumberDivTarget.classList.remove("hidden")
      } else {
        this.houseNumberDivTarget.classList.add("hidden")
      }

      if (this.hasHouseNumberTarget) {
        this.houseNumberTarget.required = streetNrRequired(country)
      }
    }

    if (this.hasStreet2DivTarget && country) {
      if (showStreet2(country) || this.street2Target.value.length > 0) {
        this.street2DivTarget.classList.remove("hidden")
      } else {
        this.street2DivTarget.classList.add("hidden")
      }
    }

    this.setStateRequired(country, false)
  }

  updateDeliveryCountry(e?: Event) {
    e?.preventDefault()

    let deliveryCountry
    if (this.hasDeliveryCountryTarget) {
      deliveryCountry = this.deliveryCountryTarget.value
    } else {
      deliveryCountry = this.deliveryCountryValue
    }

    if (deliveryCountry && this.hasDeliveryHouseNumberDivTarget) {
      if (streetNrRequired(deliveryCountry) || this.deliveryHouseNumberTarget.value.length > 0) {
        this.deliveryHouseNumberDivTarget.classList.remove("hidden")
      } else {
        this.deliveryHouseNumberDivTarget.classList.add("hidden")
      }

      if (this.hasDeliveryHouseNumberTarget) {
        this.deliveryHouseNumberTarget.required = streetNrRequired(deliveryCountry)
      }
    }

    if (this.hasDeliveryStreet2DivTarget && deliveryCountry) {
      if (showStreet2(deliveryCountry) || this.deliveryStreet2Target.value.length > 0) {
        this.deliveryStreet2DivTarget.classList.remove("hidden")
      } else {
        this.deliveryStreet2DivTarget.classList.add("hidden")
      }
    }

    this.setStateRequired(deliveryCountry, true)
  }

  toggleDelivery(e?: Event) {
    e?.preventDefault()

    if (this.hasDifferentDeliveryAddressTarget) {
      this.setDeliveryRequired(this.differentDeliveryAddressTarget.checked)
    }
  }

  validateVat(e?: Event) {
    e?.preventDefault()

    if (this.hasVatIdTarget) {
      if (
        this.vatIdTarget.value.length == 0 ||
        validations.validateVat(this.vatIdTarget.value, this.deliveryCountryValue)
      ) {
        this.vatIdTarget.classList.remove("!border-red-400")

        if (this.hasVatCheckTarget && this.hasInvoiceCompanyValue && this.invoiceCompanyValue.length > 0) {
          this.vatCheckTarget.disabled = false
        }

        if (this.hasVatIdErrorTarget) {
          this.vatIdErrorTarget.classList.add("hidden")
        }
      } else {
        this.vatIdTarget.classList.add("!border-red-400")

        if (this.hasVatCheckTarget) {
          this.vatCheckTarget.disabled = true
        }

        if (this.hasVatIdErrorTarget) {
          this.vatIdErrorTarget.classList.remove("hidden")
        }
      }
    }
  }

  private setStateRequired(country: string, delivery: boolean) {
    if (this.hasStateAUTarget) {
      const hasAUTarget = delivery ? this.hasDeliveryStateAUTarget : this.hasStateAUTarget
      const AUTarget = delivery ? this.deliveryStateAUTarget : this.stateAUTarget
      const hasUSTarget = delivery ? this.hasDeliveryStateUSTarget : this.hasStateUSTarget
      const USTarget = delivery ? this.deliveryStateUSTarget : this.stateUSTarget
      const hasStateTarget = delivery ? this.hasDeliveryStateTarget : this.hasStateTarget
      const stateTarget = delivery ? this.deliveryStateTarget : this.stateTarget

      switch (country) {
        case "AU":
          if (hasAUTarget) this.updateField(AUTarget, false, true)
          if (hasUSTarget) this.updateField(USTarget, true, false)
          if (hasStateTarget) this.updateField(stateTarget, true, false)
          break
        case "US":
          if (hasUSTarget) this.updateField(USTarget, false, true)
          if (hasAUTarget) this.updateField(AUTarget, true, false)
          if (hasStateTarget) this.updateField(stateTarget, true, false)
          break
        default:
          if (hasStateTarget) this.updateField(stateTarget, false, false)
          if (hasUSTarget) this.updateField(USTarget, true, false)
          if (hasAUTarget) this.updateField(AUTarget, true, false)
      }
    }
  }

  private setDeliveryRequired(required: boolean) {
    if (this.hasDifferentDeliveryFormTarget) {
      if (required) {
        this.differentDeliveryFormTarget.classList.remove("hidden")
      } else {
        this.differentDeliveryFormTarget.classList.add("hidden")
      }
    }

    if (this.hasTopAddressTitleTarget) {
      if (required) {
        this.topAddressTitleTarget.innerHTML = "Invoice Address"
      } else {
        this.topAddressTitleTarget.innerHTML = "Delivery Address"
      }
    }

    if (this.hasDeliveryFirstNameTarget) {
      this.deliveryFirstNameTarget.required = required
    }

    if (this.hasDeliveryNameTarget) {
      this.deliveryNameTarget.required = required
    }

    if (this.hasDeliveryStreetTarget) {
      this.deliveryStreetTarget.required = required
    }

    if (this.hasDeliveryCityTarget) {
      this.deliveryCityTarget.required = required
    }

    if (this.hasDeliveryZipcodeTarget) {
      this.deliveryZipcodeTarget.required = required
    }

    if (this.hasDeliveryHouseNumberTarget && this.hasDeliveryCountryTarget) {
      const deliveryCountry = this.deliveryCountryTarget.value

      this.deliveryHouseNumberTarget.required = required && streetNrRequired(deliveryCountry)
    }

    if (this.hasDeliveryCountryTarget && this.hasDeliveryStateTarget) {
      const deliveryCountry = this.deliveryCountryTarget.value

      if (required) {
        this.setStateRequired(deliveryCountry, true)
      } else {
        this.updateField(this.deliveryStateTarget, false, false)
        this.updateField(this.deliveryStateAUTarget, true, false)
        this.updateField(this.deliveryStateUSTarget, true, false)
      }
    }

    if (this.hasDeliveryPhoneTarget) {
      this.deliveryPhoneTarget.required = required
    }

    if (this.hasPhone1Target) {
      this.phone1Target.required = !required
    }
  }

  private updateField(field: HTMLInputElement, hide: boolean, required: boolean) {
    field.required = required

    if (hide) {
      field.parentElement?.classList.add("hidden")
    } else {
      field.parentElement?.classList.remove("hidden")
    }
  }
}
