import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["closed"]

  declare closedClass: string

  connect() {
    document.addEventListener("keydown", e => this.onEnter(e))
    setTimeout(() => {
      this.close()
    }, 10000)
  }

  onEnter(e?: KeyboardEvent) {
    if (!this.element.classList.contains("hidden") && e?.key === "Enter") {
      e?.preventDefault()
      this.close()
    }
  }

  close() {
    this.element.classList.add(this.closedClass)
    document.getElementById("find_id")?.focus()
  }
}
