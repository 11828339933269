import EpicVite from "@skyltmax/epic_vite"

EpicVite.registerDynamic("Admin", () => import("./admin").then(m => m.default))
EpicVite.registerDynamic("AutoComplete", () => import("./autocomplete").then(m => m.default))
EpicVite.registerDynamic("Checklist", () => import("./checklist").then(m => m.default))
EpicVite.registerDynamic("CMSUploader", () => import("./cms_uploader").then(m => m.default))
EpicVite.registerDynamic("DocumentCC", () => import("./document_cc").then(m => m.default))
EpicVite.registerDynamic("DocumentCopySend", () => import("./document_copy_send").then(m => m.default))
EpicVite.registerDynamic("Einvoice", () => import("./einvoice").then(m => m.default))
EpicVite.registerDynamic("GoalDashboard", () => import("./goal_dashboard").then(m => m.default))
EpicVite.registerDynamic("OrderItems", () => import("./order_items").then(m => m.default))
EpicVite.registerDynamic("OrdersShow", () => import("./orders/show").then(m => m.default))
EpicVite.registerDynamic("PackingDashboard", () => import("./packing_dashboard").then(m => m.default))
EpicVite.registerDynamic("Refund", () => import("./refund").then(m => m.default))
EpicVite.registerDynamic("WebsiteLink", () => import("./website_link").then(m => m.default))
EpicVite.registerDynamic("WebsiteLocale", () => import("./website_locale").then(m => m.default))
